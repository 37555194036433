#utHeader ul {
  margin: 0;
}

#utFooter a:hover {
  border-bottom: 1px solid #00c46e;
}
/* BELOW CSS IMPACTED COMMAN CORE ELEMENTS */
/* .layout-container {
  display:flex;
  flex-direction: column;
  justify-content: space-between;
}

.layout-phone {
  display:flex;
  flex-direction: column;
  justify-content: end;
} */